<template>
  <v-main class="pt-2 pt-sm-2 pt-xs-2 pt-md-0 pt-lg-0 pt-xl-0">
    <v-container>
      <div class="container" align="center" justify="center">
        <v-row>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/kylejesstwo_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/kylejessone_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/happy_oliver_cathedral_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/smiling_iroh_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/ollietongue_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/charlie_flower_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
           <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/aaroncandid_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/airbearpointer_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/antwaanCrystalMtn_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/dblifestyleparkinglot_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/oliverwinking_thumb.jpg"> </v-img>
            </v-card>
          </v-col>

          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/tylernasty_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/wildcardportrait_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-main>
</template>

<script>
export default {};
</script>

<style></style>
