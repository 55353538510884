<template>
  <v-main class="pt-2 pt-sm-2 pt-xs-2 pt-md-0 pt-lg-0 pt-xl-0">
    <v-container>
      <div class="container" align="center" justify="center">
        <v-row>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/andromeda_thumb.jpg"> </v-img>
              <v-card-title class="justify-center"
                >Andromeda Galaxy</v-card-title
              >
              <v-card-subtitle>Nikon D7500 + Explore Scientific ED80CF telescope</v-card-subtitle>
              <v-card-text>Exposure Info: 37s at ISO 2000 (30 minutes exposure total)</v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/LagoonTrifidNebulae_261s_iso1250_thumb.jpg"> </v-img>
              <v-card-title class="justify-center"
                >Lagoon and Trifid Nebulae</v-card-title
              >
              <v-card-subtitle>Nikon D7500 + Explore Scientific ED80CF telescope</v-card-subtitle>
              <v-card-text>Exposure Info: 261s ISO 1250 (30 minutes exposure total)</v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/chairliftmilkyway_thumb.jpg"> </v-img>
              <v-card-title class="justify-center"
                >Milky Way at Seven Springs</v-card-title
              >
              <v-card-subtitle>Sony A7III + 20mm 1.8 G lens</v-card-subtitle>
              <v-card-text>Exposure Info: 15s f1.8 ISO 1600</v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/dollysodsnightshot_thumb.jpg"> </v-img>
              <v-card-title class="justify-center"
                >Dolly Sods Night Shot</v-card-title
              >
              <v-card-subtitle>Nikon D3300 + Tokina 11-16mm f2.8 lens</v-card-subtitle>
              <v-card-text>Exposure Info: 25s 11mm f2.8 ISO 1600</v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/m8m20_thumb.jpg"> </v-img>
              <v-card-title class="justify-center"
                >Lagoon and Trifid Nebula</v-card-title
              >
              <v-card-subtitle>Nikon D3300 + Nikon 55-300mm f5.6-6.8 lens</v-card-subtitle>
              <v-card-text>Exposure Info: 49s 180mm f5.6 ISO 1600</v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/m31tripod_thumb.jpg"> </v-img>
              <v-card-title class="justify-center"
                >Winter Milky Way and Andromeda</v-card-title
              >
              <v-card-subtitle>Nikon D3300 18-35mm Kit Lens</v-card-subtitle>
              <v-card-text>Exposure Info: </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/m42_55s_thumb.jpg"> </v-img>
              <v-card-title class="justify-center">Orion Nebula</v-card-title>
              <v-card-subtitle>Nikon D3300 +</v-card-subtitle>
              <v-card-text>Exposure Info: 55s  </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/m45_54s_thumb.jpg"> </v-img>
              <v-card-title class="justify-center">Pleiades Cluster</v-card-title>
              <v-card-subtitle>Nikon D73300 + 70-300mm f5-6.5</v-card-subtitle>
              <v-card-text>Exposure Info: 55s 200mm @ f5</v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/milkywaycore50mm_thumb.jpg"> </v-img>
              <v-card-title class="justify-center">Milky Way Core</v-card-title>
              <v-card-subtitle>Nikon D7500 + Tokina 11-16mm f2.8</v-card-subtitle>
              <v-card-text>Exposure Info: </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/MilkyWayCore50mmStitch_thumb.jpg"> </v-img>
              <v-card-title class="justify-center">Milky Way Core</v-card-title>
              <v-card-subtitle>Nikon D3300 18-35mm Kit Lens</v-card-subtitle>
              <v-card-text>Exposure Info: </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/milkywaymeteor_thumb.jpg"> </v-img>
              <v-card-title class="justify-center">Milky Way Reflection</v-card-title>
              <v-card-subtitle>Nikon D3300 18-35mm Kit Lens</v-card-subtitle>
              <v-card-text>Exposure Info: </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/milkywaysevenspringsreflection_thumb.jpg">
              </v-img>
              <v-card-title class="justify-center">Milky Way Reflection</v-card-title>
              <v-card-subtitle>Nikon D3300 50mm 1.8G</v-card-subtitle>
              <v-card-text>Exposure Info: </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/treemilkyway_thumb.jpg"> </v-img>
              <v-card-title class="justify-center">Milky Way</v-card-title>
              <v-card-subtitle>Sony A7iii + Tamron 28-75 f2.8</v-card-subtitle>
              <v-card-text>Exposure Info: </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/vertchairliftmilkyway_thumb.jpg"> </v-img>
              <v-card-title class="justify-center">7 Springs Milky Way</v-card-title>
              <v-card-subtitle>Sony A7III 20mm 1.8G</v-card-subtitle>
              <v-card-text>Exposure Info: </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-main>
</template>

<script>
export default {};
</script>

<style></style>

