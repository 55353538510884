<template>
  <v-main class="pt-2 pt-sm-2 pt-xs-2 pt-md-0 pt-lg-0 pt-xl-0">
    <v-container>
      <div class="container" align="center" justify="center">
        <v-row>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/AMD_6613_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/AMD_6764_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/andymethod_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
           <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/oakleyminipipemethod17_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
           <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/jasonmethodsequence2_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/andysequence_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/airbearmethodoakleyminipipe_thumb.jpg">
              </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/frontboard_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/overunderwildcardair_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/andyboardslide_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <!--  -->
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/DSZ_1499_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/faceoffpittkentstate_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/pokecheckincoming_thumb.jpg"> </v-img>
            </v-card>
          </v-col>

          
        </v-row>
      </div>
    </v-container>
  </v-main>
</template>

<script>
export default {};
</script>

<style></style>
