<template>
  <v-main class="pt-2 pt-sm-2 pt-xs-2 pt-md-0 pt-lg-0 pt-xl-0">
    <v-container>
      <div class="container" align="center" justify="center">
        <v-row>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/blackrockswaterfallwv_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/blackrockwvwaterfalls_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/pghheli1_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/pghheli2_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/pghheli3_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/westendoverlook_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/westendoverlookfireworks_thumb.jpg">
              </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/westendoverlookmoonrise_thumb.jpg"> </v-img>
            </v-card>
          </v-col>

          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/crystalmountain_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/mtRainy_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/lakeserenewashington_thumb.jpg"> </v-img>
            </v-card>
          </v-col>
          <v-col>
            <v-card width="500px" dark>
              <v-img src="../assets/verticalblackrockwaterfalls_thumb.jpg">
              </v-img>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-main>
</template>

<script>
export default {};
</script>

<style></style>
